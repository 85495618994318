import Password from './providers/password'
import Google from './providers/google'
import LinkedIn from './providers/linkedin'

export const providers = [
  Password({
    client_id: 'telecompaper-web',
    scope: 'offline_access email',
    endpoints: {
      logout: `${process.env.API_ROOT}/logout`,
      token: `${process.env.API_ROOT}/connect/token`,
      userinfo: `${process.env.API_ROOT}/userinfo`
    }
  }),
  Google({
    client_id: process.env.GOOGLE_CLIENT_ID,
    endpoints: {
      callback: `${process.env.BASE_URL}/auth/google`
    },
    assertion: Password({
      client_id: 'telecompaper-web',
      grant_type: 'urn:ietf:params:oauth:grant-type:google_id_token',
      scope: 'offline_access email',
      endpoints: {
        token: `${process.env.API_ROOT}/connect/token`
      }
    })
  }),
  LinkedIn({
    client_id: process.env.LINKED_IN_CLIENT_ID,
    endpoints: {
      callback: `${process.env.BASE_URL}/auth/linkedin`
    },
    assertion: Password({
      client_id: 'telecompaper-web',
      grant_type: 'urn:ietf:params:oauth:grant-type:linkedin_id_token',
      scope: 'offline_access email',
      endpoints: {
        token: `${process.env.API_ROOT}/connect/token`
      }
    })
  })
]
