import { make } from 'vuex-pathify'
import { WindowBreakpoint } from '@constants'

const getBreakPoint = () => {
  const { windowWidth } = state
  if (windowWidth >= WindowBreakpoint.xl) return 'xl'
  if (windowWidth >= WindowBreakpoint.lg) return 'lg'
  if (windowWidth >= WindowBreakpoint.md) return 'md'
  if (windowWidth >= WindowBreakpoint.sm) return 'sm'
  return 'xs'
}

const getDefaultState = () => {
  return {
    currentBreakPoint: getBreakPoint(),
    isStrapiPage: false
  }
}

const state = getDefaultState

const mutations = {
  ...make.mutations(state),
  SET_DEFAULT_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = make.actions(state)

const getters = make.getters(state)

export default {
  state,
  getters,
  actions,
  mutations
}
