const Google = (options) => ({
  id: 'google',
  name: 'Google',
  type: 'oauth',
  client_id: options.client_id,
  client_secret: options.client_secret,
  grant_type: 'authorization_code',
  scope: 'openid email profile',
  endpoints: {
    authorization: 'https://accounts.google.com/o/oauth2/v2/auth?response_type=id_token',
    callback: options.endpoints.callback,
    userinfo: 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json'
  },
  assertion: options.assertion
})

export default Google
