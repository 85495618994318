export default (context) => {
  const { store, route, next } = context
  if (typeof window !== 'undefined') {
    const routesWithFilter = ['index', 'type', 'jobs', 'dossiers', 'dossier-slug', 'article-binder', 'calendars']
    if (!routesWithFilter.includes(route.name)) {
      store.set('filters/isShow', false)
      store.set('filters/isShowClear', false)
      store.set('filters/enabled', false)
      store.set('filters/header', '')
      store.set('filters/subHeader', '')
      store.set('filters/isCardHeader', false)
    }
  } else {
    return next()
  }
}
