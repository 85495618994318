import { make } from 'vuex-pathify'
import { Price } from '@constants'

const getDefaultState = () => {
  return {
    plusExtraLang: false,
    premiumExtraLang: false,
    plusOption: Price.PLUS_YEAR.idEN,
    premiumOption: Price.PREMIUM_YEAR.idEN
  }
}

const state = getDefaultState

const mutations = {
  ...make.mutations(state),
  SET_DEFAULT_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = make.actions(state)

const getters = make.getters(state)

export default {
  state,
  getters,
  actions,
  mutations
}
