export const ContentType = Object.freeze({
  BACKGROUND: 4,
  COMMENTARY: 7,
  NEWS: 12,
  PRESS_RELEASE: 14,
  CONTAINER: 20,
  REPORT: 16,
  ADVISORY_SERVICE: 2,
  ANALYSIS: 3,
  EVENT_NEWS: 9,
  FACT: 10,
  SOURCE: 17,
  INFOGRAPHIC: 11,
  VIDEO: 18,
  CONTAINER: 20,
  WHITE_PAPER: 19,
  BRIEF: 5,
  CHART: 6,
  COMPANY_PROFILE: 8,
  PRESENTATION: 13,
  PROFILE: 15,
  ADVERTORIAL: 1
})