import Vue from 'vue'
import component from './templates/google-ads'

export default (ctx, inject) => {
  if (typeof window === 'undefined') return
  if (typeof navigator === 'undefined' || navigator.userAgent.includes('Chrome-Lighthouse')) return

  // Module options
  const individualRefresh = false
  const collapseEmptyDivs = true
  const networkCode = process.env.GOOGLE_ADS_NETWORK

  // Instance options
  const gptAdsOptions = {
    networkCode,
    individualRefresh,
    slots: []
  }

  // Inject GPT init script
  window.googletag = window.googletag || { cmd: [] }

  window.googletag.cmd.push(function () {
    window.googletag.pubads().enableSingleRequest()
    if (individualRefresh) window.googletag.pubads().disableInitialLoad()
    if (collapseEmptyDivs) window.googletag.pubads().collapseEmptyDivs()
    window.googletag.enableServices()
  })

  Vue.component('GptAd', component.default || component)

  inject('gptAds', gptAdsOptions)
}
