//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gql } from 'graphql-tag'
import { sync } from 'vuex-pathify'
import { Language } from '@constants'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const DATA_QUERY = gql`
  query {
    countries(order: [{ name: ASC }], first: 999) {
      nodes {
        id
        name
      }
    }
    regions(order: { id: ASC }, first: 999) {
      nodes {
        id
        name
      }
    }
    contentTypes(order: [{ order: ASC }], first: 999, where: { isActive: { eq: true } }) {
      nodes {
        id
        name
      }
    }
    contentCollections(where: { isActive: { eq: true } }, order: [{ order: ASC }], first: 999) {
      nodes {
        id
        name
        slug
      }
    }
  }
`

export default {
  name: 'HeaderGroup',
  components: { DatePicker },
  data() {
    return {
      countries: [],
      contentTypes: [],
      contentCollections: [],
      regions: [],
      openPicker: false,
      filterTotal: 0
    }
  },
  fetchOnServer: true,
  async fetch() {
    this.$store.set('dataFetchingCount/dataFetchingCount', this.$store.get('dataFetchingCount/dataFetchingCount') + 1)
    await this.refresh()
    this.$store.set('dataFetchingCount/dataFetchedCount', this.$store.get('dataFetchingCount/dataFetchedCount') + 1)
  },
  computed: {
    breadcrumb() {
      return this.$store.state.breadcrumb
    },
    filters: sync('filters'),
    isStrapiPage() {
      return this.$store.get('app/isStrapiPage')
    },
    currentBreakPoint() {
      return this.$store.get('app/currentBreakPoint') || 'md'
    },
    language() {
      return this.$store.get('language/locale') || 'en'
    },
    languageId() {
      return this.language === 'en' ? Language.ENGLISH : Language.DUTCH
    }
  },
  watch: {
    async languageId() {
      await this.refresh(true)
    },
    filters: {
      handler() {
        if (!this.filters.isCustomFilter) {
          this.filters.isShowClear =
            this.filters.options.countryId !== null ||
            this.filters.options.regionId !== null ||
            this.filters.options.contentTypeId !== null ||
            this.filters.options.contentCollectionId !== null
        }

        this.filterTotal =
          (this.filters?.options?.countryId !== null ? 1 : 0) +
          (this.filters?.options?.regionId !== null ? 1 : 0) +
          (this.filters?.options?.contentTypeId !== null ? 1 : 0) +
          (this.filters?.options?.contentCollectionId !== null ? 1 : 0)
      },
      deep: true
    }
  },
  methods: {
    async refresh(purge = false) {
      try {
        const { data } = await this.$apollo.query({
          query: DATA_QUERY,
          fetchPolicy: purge ? 'network-only' : 'cache-first'
        })

        this.countries = data.countries.nodes
        this.contentTypes = data.contentTypes.nodes
        this.contentCollections = data.contentCollections.nodes
        this.regions = data.regions.nodes
      } catch (error) {
        this.handleError(error)
      }
    },
    onFiltersToggle() {
      this.filters.enabled = !this.filters.enabled
    },
    onFiltersClear() {
      this.filters.options = {
        countryId: null,
        regionId: null,
        contentTypeId: null,
        contentCollectionId: null
      }
      this.header = null
      this.$nuxt.$emit('onFiltersClear')
    },
    onFilterCollectionChange() {
      this.$nuxt.$emit('onFilterCollectionChange')
    },
    onChangeDate(date) {
      this.openPicker = false
    }
  }
}
