import Vue from 'vue'
import { formatDate } from '@utils'
import { WindowBreakpoint, ErrorCode, DateSelectionType } from '@constants'

Vue.mixin({
  data() {
    return {
      defaultFilters: {
        order: 'date:desc',
        text: '',
        types: [],
        queryBy: {
          TITLE: true,
          FULLTEXT: true,
          COMPANY: true
        },
        editions: [],
        country: null,
        tags: [],
        dateChoice: DateSelectionType.PAST_YEAR,
        dateStart: new Date(),
        dateEnd: new Date()
      }
    }
  },
  beforeMount() {
    if (typeof window !== 'undefined') {
      const routesWithoutBreadcrumbs = ['index', 'login', 'payment']
      if (routesWithoutBreadcrumbs.includes(this.$route.name)) {
        this.setBreadcrumb({})
      }

      const routesWithFilter = [
        'index',
        'type',
        'jobs',
        'dossiers',
        'dossier-slug',
        'article-binder',
        'calendars',
        'all'
      ]
      if (!routesWithFilter.includes(this.$route.name)) {
        this.setFilters({})
      }
    }
  },
  methods: {
    setBreadcrumb({ isShow, pageTitle, pageCategory, categoryRedirectUrl }) {
      this.$store.set('breadcrumb/isShow', isShow ?? false)
      this.$store.set('breadcrumb/pageTitle', pageTitle ?? null)
      this.$store.set('breadcrumb/pageCategory', pageCategory ?? null)
      this.$store.set('breadcrumb/categoryRedirectUrl', categoryRedirectUrl ?? null)
    },
    setFilters({
      isShow,
      isShowClear,
      showFilterTotal,
      enabled,
      header,
      isCardHeader,
      isCustomFilter,
      subHeader,
      isShowDate,
      timeframe
    }) {
      this.$store.set('filters/isShow', isShow ?? false)
      this.$store.set('filters/showFilterTotal', showFilterTotal ?? false)
      this.$store.set('filters/isShowClear', isShowClear ?? false)
      this.$store.set('filters/isShowDate', isShowDate ?? false)
      this.$store.set('filters/timeframe', timeframe ?? 'day')
      this.$store.set('filters/enabled', enabled ?? false)
      this.$store.set('filters/header', header ?? '')
      this.$store.set('filters/subHeader', subHeader ?? '')
      this.$store.set('filters/isCardHeader', isCardHeader ?? false)
      this.$store.set('filters/isCustomFilter', isCustomFilter ?? false)
    },
    setFilterOptions({ countryId = null, regionId = null, contentTypeId = null, contentCollectionId = null }) {
      this.$store.set('filters/options@countryId', countryId)
      this.$store.set('filters/options@regionId', regionId)
      this.$store.set('filters/options@contentTypeId', contentTypeId)
      this.$store.set('filters/options@contentCollectionId', contentCollectionId)
    },
    formatDate(date) {
      return formatDate(this.$dayjs, date)
    },
    formatTime(date) {
      return this.$dayjs(date).format('HH:mm')
    },
    xsBreakPoint() {
      return Object.keys(WindowBreakpoint).find((key) => WindowBreakpoint[key] === WindowBreakpoint.xs)
    },
    smBreakPoint() {
      return Object.keys(WindowBreakpoint).find((key) => WindowBreakpoint[key] === WindowBreakpoint.sm)
    },
    mdBreakPoint() {
      return Object.keys(WindowBreakpoint).find((key) => WindowBreakpoint[key] === WindowBreakpoint.md)
    },
    handleError(error) {
      console.error('Error: ', error, { ...error })
      const { networkError, graphQLErrors } = error
      if (networkError || graphQLErrors) {
        const errors = networkError && networkError.result ? networkError.result.errors : graphQLErrors
        if (errors.length > 0) {
          if (errors.some((x) => x.extensions.code === ErrorCode.DATA_EXISTS)) {
            const error = errors.find((x) => x.extensions.code === ErrorCode.DATA_EXISTS)
            return error.message
          } else if (errors.some((x) => x.extensions.code === ErrorCode.NOT_FOUND)) {
            const error = errors.find((x) => x.extensions.code === ErrorCode.NOT_FOUND)
            return error.message
          } else if (
            errors.some(
              (x) =>
                x.extensions.code === ErrorCode.UNAUTHORISED || x.extensions.code === ErrorCode.AUTH_NOT_AUTHENTICATED
            )
          ) {
            const error = errors.find((x) => x.extensions.code === ErrorCode.UNAUTHORISED)
            return error.message
          } else if (errors.some((x) => x.extensions.code === ErrorCode.BAD_INPUT)) {
            const error = errors.find((x) => x.extensions.code === ErrorCode.BAD_INPUT)
            return error.message
          } else {
            return errors[0]?.message
          }
        }
      }

      if (error.isAxiosError) {
        return error.response.data.error ?? error.response.data
      }

      if (error.message) {
        if (error.message.startsWith('Error')) {
          return error.message.split(': ')[1]
        } else if (error.message === 'invalid_request') {
          // ignore this kind of error
          return null
        } else {
          return error.message
        }
      }

      this.$nuxt.error({ statusCode: error.statusCode, message: error.message })
    },
    openCart(open = null) {
      this.$nuxt.$emit('openingCart', open)
    },
    isBrowser() {
      return typeof window !== 'undefined'
    },
    encodeValue(value) {
      if (!value) {
        return null
      }

      const valueToString = value.toString()

      if (this.isBrowser()) {
        return btoa(valueToString)
      }

      const buff = Buffer.from(valueToString, 'ascii')
      return buff.toString('base64')
    },
    getContentTypeUrlName(contentType) {
      if (contentType.contentTypeGroup) return contentType.contentTypeGroup.name.replace(/\s+/g, '-').toLowerCase()
      else return contentType.name.replace(/\s+/g, '-').toLowerCase()
    },
    scrollToSection(section) {
      window.scrollTo({
        top: document.querySelector(section).offsetTop,
        behavior: 'smooth'
      })
    }
  }
})
