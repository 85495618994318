import qs from 'query-string'

export const getTokens = async (provider, username, password) => {
  const url = provider.endpoints.token

  if (!url) {
    throw new Error(`${provider.name} has no token endpoint defined`)
  }

  const params = {
    grant_type: provider.grant_type,
    client_id: provider.client_id,
    client_secret: provider.client_secret,
    scope: provider.scope,
    username,
    password
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: qs.stringify(params)
  })

  if (!response.ok) {
    const json = await response.json()
    throw new Error(json?.error_description ?? 'An unknown error occurred while logging you in')
  }

  return response.json()
}
