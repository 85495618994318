import { signin, refresh } from './library/helpers'
import { providers } from './config'

class AuthModule {
  context

  constructor(context) {
    this.context = context
  }

  get providers() {
    return providers
  }

  get isAuthenticated() {
    return this.refreshToken != null
  }

  get accessToken() {
    if (typeof localStorage === 'undefined') {
      return null
    }

    return localStorage.getItem('access_token')
  }

  get refreshToken() {
    if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
      return null
    }

    const local = localStorage.getItem('refresh_token')
    if (!local) {
      return sessionStorage.getItem('refresh_token')
    }

    return local
  }

  #store = (tokens, remember) => {
    if (typeof localStorage === 'undefined') {
      return
    }

    if (remember) {
      localStorage.setItem('refresh_token', tokens.refresh_token)
    } else {
      sessionStorage.setItem('refresh_token', tokens.refresh_token)
    }

    localStorage.setItem('access_token', tokens.access_token)
  }

  #redirect = () => {
    // NOTE: don't redirect here... when logging in, we want the user to stay on the same page.
    // You can listen for the 'on-auth-change' event if you need to change things based on auth status
    // this.context.redirect('/')
  }

  getUser = async () => {
    if (!this.isAuthenticated) {
      return null
    }

    const query = `
    {
      me {
        id
        email
        firstName
        lastName
        company
        jobFunctionId
        userLevels(
          where: {
            and: [
              { levelId: { in: [3, 4, 8, 40, 41, 42, 50, 51, 52] } }
              { or: [{ endDate: { eq: null } }, { endDate: { gt: "${new Date().toISOString()}" } }] }
            ]
          }
        ) {
          levelId
        }
        trialReactivationDate
        userArticles {
          article {
            id
          }
        }
      }
    }
    `

    const response = await fetch(process.env.GRAPHQL_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.accessToken}`
      },
      body: JSON.stringify({ query })
    })

    const keys = response.headers.keys()

    // iterate over all incoming headers from the response
    let header = keys.next()
    while (header.value) {
      const name = header.value.toLowerCase()
      const value = response.headers.get(header.value)

      // where the header start with x-emit- we need to emit a message with the header value
      if (name.startsWith('x-emit-')) {
        // emits the message where the message name will be the value after x-emit-, eg; x-emit-search-results-found would have message name of search-results-found
        window.$nuxt.$emit(name.split('x-emit-').pop(), value)
      }

      header = keys.next()
    }

    const { data } = await response.json()

    this.context.store.set(
      'bookmark/articleIds',
      data.me.userArticles.map((x) => x.article.id)
    )

    return data.me
  }

  getProvider(providerId) {
    return this.providers.find(({ id }) => id === providerId)
  }

  login = async (providerId, payload, remember = true) => {
    const provider = this.getProvider(providerId)
    if (provider == null) {
      throw new Error(`Cannot find provider with ${providerId}.`)
    }

    const tokens = await signin(provider, payload)
    this.#store(tokens, remember)
    const user = await this.getUser()
    this.context.store.set('user/user', user)

    window.$nuxt.$emit('on-auth-change', true)
    this.#redirect()
  }

  refresh = async () => {
    const tokens = await refresh()
    this.#store(tokens, true)
  }

  expired = () => {
    return false
  }

  logout = () => {
    if (typeof localStorage === 'undefined') {
      return
    }

    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    sessionStorage.removeItem('refresh_token')
    this.context.store.set('user/user', null)
    window.$nuxt.$emit('on-auth-change', false)
  }
}

export default AuthModule
