import qs from 'query-string'

const getNonce = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

export const getAuthorizationUrl = (provider) => {
  if (provider.endpoints.authorization == null) {
    throw new Error(`${provider.name} has no authorization endpoint defined`)
  }

  if (provider.endpoints.callback == null) {
    throw new Error(`${provider.name} has no callback endpoint defined`)
  }

  const endpoint = new URL(provider.endpoints.authorization)
  const params = {
    client_id: provider.client_id,
    redirect_uri: provider.endpoints.callback,
    scope: provider.scope
  }

  // Set Nonce Value if response_type contains id_token to mitigate Replay Attacks
  // More Info: https://openid.net/specs/openid-connect-core-1_0.html#NonceNotes
  // More Info: https://tools.ietf.org/html/draft-ietf-oauth-v2-threatmodel-06#section-4.6.2
  if (provider.endpoints.authorization.includes('id_token')) {
    params.nonce = getNonce(10)
  }

  let url = `${endpoint.origin + endpoint.pathname}?${qs.stringify(params)}`

  // if the endpoint contains additional query string data append it to the url
  if (provider.endpoints.authorization.includes('?')) {
    const parseUrl = new URL(provider.endpoints.authorization)
    const baseUrl = `${parseUrl.origin}${parseUrl.pathname}?`
    url = url.replace(baseUrl, `${provider.endpoints.authorization}&`)
  }

  return url
}
