const LinkedIn = (options) => ({
  id: 'linkedin',
  name: 'LinkedIn',
  type: 'oauth',
  client_id: options.client_id,
  client_secret: options.client_secret,
  grant_type: 'authorization_code',
  scope: 'r_emailaddress r_liteprofile',
  endpoints: {
    authorization: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    callback: options.endpoints.callback,
    userinfo: 'https://api.linkedin.com/v2/me'
  },
  assertion: options.assertion
})

export default LinkedIn
