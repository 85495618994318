import { make } from 'vuex-pathify'

const addYears = (date, years) => {
  date.setFullYear(date.getFullYear() + years)
  return date
}

const getDefaultState = () => {
  return {
    filters: {
      idFeed: 0,
      startDate: addYears(new Date(), -1).toISOString().split('T')[0],
      endDate: new Date().toISOString().split('T')[0]
    }
  }
}

const state = getDefaultState

const mutations = {
  ...make.mutations(state),
  SET_DEFAULT_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = make.actions(state)

const getters = make.getters(state)

export default {
  state,
  getters,
  actions,
  mutations
}
