import detectBrowserLanguage from 'detect-browser-language'
import { Price } from '@constants'

window.onNuxtReady(({ $store }) => {
  if ($store.get('language/hasManuallyChanged')) return

  const currentLocale = $store.get('language/locale')

  let browserLocale = 'en'
  const browserLanguage = detectBrowserLanguage()
  if (browserLanguage.indexOf('nl') === 0) browserLocale = 'nl'

  if (currentLocale !== browserLocale) {
    $store.set('language/locale', browserLocale)
  }

  $store.set('subscription/plusOption', browserLocale === 'en' ? Price.PLUS_YEAR.idEN : Price.PLUS_YEAR.idNL)
  $store.set('subscription/premiumOption', browserLocale === 'en' ? Price.PREMIUM_YEAR.idEN : Price.PREMIUM_YEAR.idNL)
})
