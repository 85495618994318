//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useBreakpointWatcher } from '@uses'
import { gql } from 'graphql-tag'
import { sync } from 'vuex-pathify'
import { Language, ContentCollection, ContentType } from '@constants'

const DATA_QUERY = gql`
  query ($whereContainer: ContainerFilterInput) {
    containers(where: $whereContainer) {
      nodes {
        id
        name
        mainPoints
        slug
        timeframe
        languageId
        description
        websiteTextBefore {
          id
          html
        }
        websiteTextAfter {
          id
          html
        }
      }
    }
  }
`

const ARTICLE_QUERY = gql`
  query ($whereArticle: ContainerArticleFilterInput) {
    containerArticles(where: $whereArticle, order: { article: { date: DESC } }, first: 999) {
      nodes {
        id
        title
        isMainPoint
        useImage
        description
        hasMoreContent
        article {
          id
          contentTypeId
          imageUrl
          date
          slug
          externalId
          redirectUrl
          edition {
            id
            name
          }
          country {
            id
            name
          }
          region {
            id
            name
          }
          contentType {
            id
            name
            colour
          }
          articleUpdates {
            id
            changeText
            changedAt
          }
        }
      }
    }
  }
`

export default {
  scrollToTop: true,
  setup() {
    const { resizeHandler } = useBreakpointWatcher()
    return {
      resizeHandler
    }
  },
  data() {
    return {
      isSearching: false,
      isCartOpen: false,
      isSearchFocused: false,
      showPlaceholder: true,
      placeholders: 3,
      timeout: 0,
      articles: [],
      container: null,
      ContentType,
      dateFrom: null,
      dateTo: null,
      runningRefresh: false,
      containers: [],
      head: {}
    }
  },
  async fetch() {
    this.type = this.queryType
    await this.refresh()
  },
  head() {
    // return this.head

    const head = {
      title: this.container ? `${this.container?.name} - Telecompaper` : 'Telecompaper',
      htmlAttrs: {
        lang: this.language
      }
    }

    if (typeof navigator !== 'undefined' && !navigator.userAgent.includes('Chrome-Lighthouse')) {
      head.script = [
        {
          src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
          async: true,
          defer: true
        }
      ]
    }
    console.log('description', this.container?.description)
    if (this.container?.description) {
      head.meta = [
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: this.container?.description
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.container?.description
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          property: 'twitter:description',
          content: this.container?.description
        }
      ]
    }

    return head
  },
  computed: {
    filters: sync('filters'),
    queryType() {
      return this.$route.params.type
    },
    currentBreakPoint() {
      return this.$store.get('app/currentBreakPoint') || 'md'
    },
    language() {
      return this.$store.get('language/locale') || 'en'
    },
    languageId() {
      return this.language === 'en' ? Language.ENGLISH : Language.DUTCH
    },
    slug() {
      return this.$route.path.toLowerCase()
    }
  },
  watch: {
    languageId() {
      if (this.runningRefresh) return
      if (this.container && this.container.languageId !== this.languageId) {
        if (!this.containers || this.containers.length <= 1) this.$router.push({ path: '/' })
        else this.container = this.containers.filter((x) => x.languageId === this.languageId)
      }
    },
    'filters.options': {
      async handler() {
        if (this.runningRefresh) return
        await this.refresh(true)
      },
      deep: true
    },
    'filters.dateStart': {
      async handler() {
        if (this.runningRefresh) return
        await this.refresh(true)
      },
      deep: true
    }
  },
  fetchOnServer: true,
  beforeDestroy() {
    this.$nuxt.$off('searching')
    this.$nuxt.$off('openingCart')
    this.$nuxt.$off('clearFilters')
    this.$nuxt.$off('onFiltersClear')
  },
  mounted() {
    this.$nuxt.$on('searching', (isSearching) => (this.isSearching = isSearching))
    this.$nuxt.$on('openingCart', (isCartOpen) => (this.isCartOpen = isCartOpen))
    this.$nuxt.$on('clearFilters', () => this.$refs.filter.onFiltersClear())

    this.filters.dateStart = new Date()
    this.setHeader()
    this.$nuxt.$on('onFiltersClear', this.onFiltersClear)
  },
  methods: {
    async refresh(purge = false) {
      this.filters.isLoading = true
      this.runningRefresh = true
      try {
        const whereContainer = {
          slug: {
            eq: this.slug
          }
        }

        try {
          const { data } = await this.$apollo.query({
            query: DATA_QUERY,
            variables: {
              whereContainer
            },
            fetchPolicy: purge ? 'network-only' : 'cache-first'
          })

          if (!data || !data.containers || data.containers.nodes.length === 0) {
            this.$router.push({ path: '/' })
            return
          }

          this.containers = data.containers.nodes
          if (this.containers.length === 1) {
            this.container = this.containers[0]

            // if the container langauge doesn't match the site language, change the site language
            if (this.container.languageId !== this.languageId) {
              this.$store.set('language/locale', this.container.languageId === Language.ENGLISH ? 'en' : 'nl')
            }
          } else {
            this.container = this.containers.filter((x) => x.languageId === this.languageId)[0]
          }

          if (!this.container) {
            this.$router.push({ path: '/' })
            return
          }

          const whereArticle = {
            container: {
              slug: {
                eq: this.slug
              }
            },
            article: {
              isActive: {
                eq: true
              }
            }
          }
          if (this.container.timeframe !== 'unlimited') {
            this.dateFrom = this.$dayjs(this.filters.dateStart).startOf(this.container.timeframe)
            this.dateTo = this.$dayjs(this.filters.dateStart).endOf(this.container.timeframe)

            whereArticle.article.date = {
              gte: this.dateFrom,
              lt: this.dateTo
            }
          } else {
            this.dateFrom = null
            this.dateTo = null
          }
          if (this.filters.options.countryId != null) {
            whereArticle.article.country = {
              id: {
                eq: this.filters.options.countryId
              }
            }
          }
          if (this.filters.options.regionId != null) {
            whereArticle.article.region = {
              id: {
                eq: this.filters.options.regionId
              }
            }
          }
          if (this.filters.options.contentCollectionId != null) {
            whereArticle.article.contentCollectionArticles = {
              some: {
                contentCollectionId: {
                  eq: this.filters.options.contentCollectionId
                }
              }
            }
          }
          if (this.filters.options.contentTypeId != null) {
            whereArticle.article.contentType = {
              id: {
                eq: this.filters.options.contentTypeId
              }
            }
          }
          if (this.filters.options.contentCollectionId !== ContentCollection.RESEARCH) {
            whereArticle.article.language = {
              id: {
                eq: this.languageId
              }
            }
          }

          const articlesResponse = await this.$apollo.query({
            query: ARTICLE_QUERY,
            variables: {
              whereArticle
            },
            fetchPolicy: purge ? 'network-only' : 'cache-first'
          })

          let articles = articlesResponse.data.containerArticles.nodes

          // We still need to insert the "updates" at the appropriate places in the array
          articles = articles.reverse()

          for (let i = 0; i < articles.length; i++) {
            const item = articles[i]
            if (item.article && item.article.articleUpdates) {
              for (let j = 0; j < item.article.articleUpdates.length; j++) {
                const update = item.article.articleUpdates[j]
                const newRecord = {
                  id: `${item.id}-${update.id}`,
                  isUpdate: true,
                  title: item.title,
                  isMainPoint: false,
                  description: `<p>${update.changeText}</p>`,
                  article: {
                    id: item.article.id,
                    externalId: item.article.externalId,
                    slug: item.article.slug,
                    date: update.changedAt,
                    edition: item.article.edition,
                    country: item.article.country,
                    region: item.article.region,
                    contentType: item.article.contentType
                  }
                }

                // Peek ahead to the next item in the array until we find one that is later than
                // the current date/time and insert, or add to the end of the array
                let inserted = false
                for (let x = i + 1; x < articles.length; x++) {
                  if (articles[x].article.date > update.changedAt) {
                    articles.splice(x, 0, newRecord)
                    inserted = true
                    break
                  }
                }
                if (!inserted) articles.push(newRecord)
              }
            }
          }

          this.articles = articles.reverse()
          this.setHeader()
        } catch (error) {
          this.handleError(error)
        } finally {
          this.showPlaceholder = false
        }
      } catch (error) {
        this.handleError(error)
      } finally {
        this.filters.isLoading = false
        this.runningRefresh = false
      }
    },
    setHeader() {
      this.setBreadcrumb({
        isShow: true,
        pageTitle: this.container?.name
      })
      this.setFilters({
        isShow: true,
        showFilterTotal: true,
        isShowDate: this.container?.timeframe && this.container?.timeframe !== 'unlimited',
        header: this.container?.name,
        timeframe: this.container?.timeframe,
        isCardHeader: false,
        subHeader:
          this.container?.timeframe === 'unlimited'
            ? null
            : this.container?.timeframe === 'year'
            ? this.$dayjs(this.filters.dateStart).format('YYYY')
            : this.container?.timeframe === 'month'
            ? this.$dayjs(this.filters.dateStart).format('MMM YYYY')
            : this.container?.timeframe === 'week'
            ? `${this.$dayjs(this.dateFrom).format('D MMM YYYY')} - ${this.$dayjs(this.dateTo).format('D MMM YYYY')}`
            : this.$dayjs(this.filters.dateStart).format('D MMM YYYY')
      })
    },
    async onFiltersClear() {
      await this.refresh(true)
    }
  }
}
