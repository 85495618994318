import fetch from 'node-fetch'
import qs from 'query-string'

export const doTokenAssertion = async (provider, idToken, locale) => {
  if (provider.assertion == null) {
    throw new Error(`${provider.name} has assertion configured but was not passed`)
  }

  const url = provider.assertion.endpoints.token
  const params = {
    grant_type: provider.assertion.grant_type,
    client_id: provider.assertion.client_id,
    client_secret: provider.assertion.client_secret,
    redirect_uri: provider.endpoints.callback,
    scope: provider.assertion.scope,
    assertion: idToken
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Request-Language': locale
    },
    body: qs.stringify(params)
  })

  if (!response.ok) {
    const json = await response.json()
    throw new Error(json.error)
  }

  return response.json()
}
