export const Price = Object.freeze({
  PLUS_MONTH: {
    price: process.env.PLUS_MONTH_PRICE_AMOUNT,
    idNL: process.env.NLD_PLUS_MONTH_PRICE,
    idEN: process.env.ENG_PLUS_MONTH_PRICE
  },
  PLUS_YEAR: {
    price: process.env.PLUS_YEAR_PRICE_AMOUNT,
    idNL: process.env.NLD_PLUS_YEAR_PRICE,
    idEN: process.env.ENG_PLUS_YEAR_PRICE
  },
  PREMIUM_MONTH: {
    price: process.env.PREMIUM_MONTH_PRICE_AMOUNT,
    idNL: process.env.NLD_PREMIUM_MONTH_PRICE,
    idEN: process.env.ENG_PREMIUM_MONTH_PRICE
  },
  PREMIUM_YEAR: {
    price: process.env.PREMIUM_YEAR_PRICE_AMOUNT,
    idNL: process.env.NLD_PREMIUM_YEAR_PRICE,
    idEN: process.env.ENG_PREMIUM_YEAR_PRICE
  }
})
