//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useBreakpointWatcher } from '@uses'
import { ContentType, Language } from '@constants'

export default {
  name: 'Generic',
  layout: 'generic',
  setup() {
    const { resizeHandler } = useBreakpointWatcher()
    return {
      resizeHandler
    }
  },
  data() {
    return {
      isSearching: false,
      isCartOpen: false,
      hasBanner: true,
      confirmationEmail: null,
      ContentType,
      Language
    }
  },
  head() {
    return this.buildHead()
  },
  computed: {
    language() {
      return this.$store.get('language/locale') || 'en'
    },
    languageId() {
      return this.language === 'en' ? Language.ENGLISH : Language.DUTCH
    },
    currentBreakPoint() {
      return this.$store.get('app/currentBreakPoint') || 'md'
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('searching')
    this.$nuxt.$off('openingCart')
    this.$nuxt.$off('topbanner')
  },
  mounted() {
    this.$nuxt.$on('searching', (isSearching) => (this.isSearching = isSearching))
    this.$nuxt.$on('openingCart', (isCartOpen) => (this.isCartOpen = isCartOpen))
    this.$nuxt.$on('topbanner', ({ isShow }) => {
      this.hasBanner = isShow
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  },
  methods: {
    buildHead() {
      const head = {
        htmlAttrs: {
          lang: this.language
        }
      }

      if (typeof navigator !== 'undefined' && !navigator.userAgent.includes('Chrome-Lighthouse')) {
        head.script = [
          {
            src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
            async: true,
            defer: true
          }
        ]
      }

      return head
    }
  }
}
