import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    articles: [],
    text: null,
    total: 0,
    yPos: 0
  }
}

const state = getDefaultState

const mutations = {
  ...make.mutations(state),
  SET_DEFAULT_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = make.actions(state)

const getters = make.getters(state)

export default {
  state,
  getters,
  actions,
  mutations
}
