//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Banner',
  props: {
    isSearching: {
      type: Boolean,
      required: true
    },
    isCartOpen: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    canShow() {
      return (
        typeof navigator !== 'undefined' &&
        !navigator.userAgent.includes('Chrome-Lighthouse') &&
        !navigator.userAgent.includes('Googlebot')
      )
    }
  },
  methods: {
    updateSize() {
      this.$emit('updateSize')
    }
  }
}
