export default (context) => {
  const { store, route, next } = context
  if (typeof window !== 'undefined') {
    const routesWithoutBreadcrumbs = ['index', 'login', 'payment']
    if (routesWithoutBreadcrumbs.includes(route.name)) {
      store.set('breadcrumb/isShow', false)
      store.set('breadcrumb/pageTitle', null)
      store.set('breadcrumb/pageCategory', null)
      store.set('breadcrumb/categoryRedirectUrl', null)
    }
  } else {
    return next()
  }
}
