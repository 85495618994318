/* eslint-disable */
const gtmKey = process.env.GTM_KEY

export default () => {
  if (process.env.NODE_ENV !== 'production') return
  if (typeof window === 'undefined') return
  if (typeof navigator === 'undefined' || navigator.userAgent.includes('Chrome-Lighthouse')) return
  /*
  ** Include Google Tag Manager
  */
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', gtmKey)
}
