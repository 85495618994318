//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useBreakpointWatcher } from '@uses'

export default {
  setup() {
    const { resizeHandler } = useBreakpointWatcher()
    return {
      resizeHandler
    }
  },
  data() {
    return {
      isSearching: false,
      isCartOpen: false,
      isSearchFocused: false
    }
  },
  head() {
    return this.buildHead()
  },
  computed: {
    language() {
      return this.$store.get('language/locale') || 'en'
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('searching')
    this.$nuxt.$off('openingCart')
    this.$nuxt.$off('clearFilters')
  },
  beforeMount() {
    this.$store.set('dataFetchingCount/dataFetchingCount', 0)
    this.$store.set('dataFetchingCount/dataFetchedCount', 0)
  },
  mounted() {
    this.$nuxt.$on('searching', (isSearching) => (this.isSearching = isSearching))
    this.$nuxt.$on('openingCart', (isCartOpen) => (this.isCartOpen = isCartOpen))
    this.$nuxt.$on('clearFilters', () => this.$refs.filter.onFiltersClear())
  },
  methods: {
    buildHead() {
      const head = {
        htmlAttrs: {
          lang: this.language
        }
      }

      if (typeof navigator !== 'undefined' && !navigator.userAgent.includes('Chrome-Lighthouse')) {
        head.script = [
          {
            src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
            async: true,
            defer: true
          }
        ]
      }

      return head
    }
  }
}
