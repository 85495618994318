import qs from 'query-string'
import { doTokenAssertion } from './assertion'
import { getTokens } from './grants/password'
import { getAuthorizationUrl } from './grants/authorization-code'

export const routeOption = (route, key, value) => {
  return route.matched.some((m) => {
    if (typeof window !== 'undefined') {
      return Object.values(m.components).some((component) => component.options && component.options[key] === value)
    } else {
      return Object.values(m.components).some((component) =>
        Object.values(component._Ctor).some((ctor) => ctor.options && ctor.options[key] === value)
      )
    }
  })
}

export const signin = async (provider, payload) => {
  if (provider.type === 'oauth' && provider.grant_type === 'password') {
    return getTokens(provider, payload.username, payload.password)
  }

  if (provider.type === 'oauth') {
    const panel = window.open(getAuthorizationUrl(provider), `Connect ${provider.name} account`, 'height=600,width=500')

    const response = await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (panel.closed) {
          clearInterval(interval)
          if (panel.document) {
            if (panel.document.URL.includes('#')) resolve(panel.document.URL.split('#')[1])
            else if (panel.document.URL.includes('?')) resolve(panel.document.URL.split('?')[1])
            else resolve(panel.document.URL)
          } else {
            resolve()
          }
        }
      }, 500)
    })

    const params = qs.parse(response)
    return await doTokenAssertion(provider, params.id_token || params.code, payload.locale)
  }

  throw new Error(`The provider ${provider.type} and grant ${provider.grant_type} is not supported`)
}

export const refresh = async () => {
  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  let token = localStorage.getItem('refresh_token')
  if (!token) {
    token = sessionStorage.getItem('refresh_token')
  }

  if (!token) {
    return null
  }

  const params = {
    grant_type: 'refresh_token',
    refresh_token: token,
    client_id: 'telecompaper-web'
  }

  const response = await fetch(`${process.env.API_ROOT}/connect/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: qs.stringify(params)
  })

  if (!response.ok) {
    throw new Error(`The response returned with a status code of ${response.status}.`)
  }

  return response.json()
}
