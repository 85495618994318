import { render, staticRenderFns } from "./generic.vue?vue&type=template&id=08c7ada8&scoped=true&"
import script from "./generic.vue?vue&type=script&lang=js&"
export * from "./generic.vue?vue&type=script&lang=js&"
import style0 from "./generic.vue?vue&type=style&index=0&id=08c7ada8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c7ada8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/home/vsts/work/1/s/components/navigation/Navbar.vue').default,HeaderGroup: require('/home/vsts/work/1/s/components/header/HeaderGroup.vue').default,Footer: require('/home/vsts/work/1/s/components/Footer.vue').default})
