export default function ({ app, store, $axios, redirect }) {
  $axios.onRequest((config) => {
    if (config.url === process.env.GRAPHQL_STRAPI_API) {
      // config.headers.Authorization = `Bearer ${process.env.STRAPI_API_TOKEN}`
      return
    }

    if (app.$auth.accessToken) config.headers.Authorization = `Bearer ${app.$auth.accessToken}`

    const user = store.get('user/user')
    if (!user && typeof localStorage !== 'undefined') {
      const visitorId = localStorage.getItem('VisitorId')
      config.headers['X-VISITOR-ID'] = visitorId
    }

    const language = store.get('language/locale')
    if (language) config.headers['X-Request-Language'] = language
  })

  const maxRetry = 3
  let retry = 1

  $axios.onResponseError(async (error) => {
    const statusCode = parseInt(error.response && error.response.status)
    if (statusCode === 401) {
      await app.$auth
        .refresh()
        .then((res) => {
          return $axios.request(error.config)
        })
        .catch(() => {
          app.$auth.logout()
          store.set('cart/items', [])
          redirect('/')
        })
    }

    if (error.config) {
      if (retry <= maxRetry && statusCode !== 500 && statusCode !== 400) {
        retry++
        return $axios.request(error.config)
      }
    }

    retry = 1

    return Promise.reject(error)
  })
}
