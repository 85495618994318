const Password = (options) => ({
  id: 'password',
  name: 'Password',
  type: 'oauth',
  client_id: options.client_id,
  client_secret: options.client_secret,
  grant_type: options.grant_type ?? 'password',
  scope: options.scope,
  endpoints: options.endpoints
})

export default Password
