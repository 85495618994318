import { setContext } from 'apollo-link-context'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ErrorCode } from '@constants'

export default ({ app, store, redirect, error }) => {
  const headers = setContext(() => {
    const headers = {}

    const locale = store.get('language/locale') || 'en'

    if (app.$auth.accessToken) {
      headers.Authorization = `Bearer ${app.$auth.accessToken}`
    }

    const user = store.get('user/user')
    if (!user && typeof localStorage !== 'undefined') {
      const visitorId = localStorage.getItem('VisitorId')
      headers['X-VISITOR-ID'] = visitorId
    }

    return {
      headers: {
        ...headers,
        'X-Request-Language': locale
      }
    }
  })

  const errorLink = onError(({ operation, graphQLErrors, networkError, forward }) => {
    console.log('networkError', networkError)
    console.log('graphQLErrors', graphQLErrors)
    if (
      graphQLErrors?.some(
        (x) => x.extensions?.code === ErrorCode.UNAUTHORISED || x.extensions.code === ErrorCode.AUTH_NOT_AUTHENTICATED
      )
    ) {
      if (app.$auth.isAuthenticated) {
        return new Observable((observer) => {
          app.$auth
            .refresh()
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              }

              forward(operation).subscribe(subscriber)
            })
            .catch((error) => {
              app.$auth.logout()
              store.set('cart/items', [])
              redirect('/')
              observer.error(error)
            })
        })
      }
    }

    if (networkError != null) {
      error({
        statusCode: 500
      })
    }
  })

  const retry = new RetryLink({
    delay: {
      initial: 100,
      max: 2000,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error) => {
        const doNotRetryCodes = [500, 400]
        return !!error && !doNotRetryCodes.includes(error.statusCode)
      }
    }
  })

  // this will emit messages to the nuxt message bus if an incoming header start with x-emit-
  const emitter = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      if (typeof window === 'undefined') return response

      const context = operation.getContext()
      const keys = context.response.headers.keys()

      // iterate over all incoming headers from the response
      let header = keys.next()
      while (header.value) {
        const name = header.value.toLowerCase()
        const value = context.response.headers.get(header.value)

        // where the header start with x-emit- we need to emit a message with the header value
        if (name.startsWith('x-emit-')) {
          // emits the message where the message name will be the value after x-emit-, eg; x-emit-search-results-found would have message name of search-results-found
          window.$nuxt.$emit(name.split('x-emit-').pop(), value)
        }

        header = keys.next()
      }

      return response
    })
  })

  const link = ApolloLink.from([errorLink, headers, retry, emitter])

  return {
    link,
    httpEndpoint: process.env.GRAPHQL_API,
    cache: new InMemoryCache()
  }
}
