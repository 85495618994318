import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    isShow: false,
    isShowDate: false,
    isShowClear: false,
    enabled: false,
    showFilterTotal: false,
    timeframe: 'day',
    dateStart: new Date(),
    header: '',
    subHeader: '',
    isCardHeader: false,
    isCustomFilter: false,
    options: {
      countryId: null,
      regionId: null,
      contentTypeId: null,
      contentCollectionId: null
    },
    isLoading: false
  }
}

const state = getDefaultState

const mutations = {
  ...make.mutations(state),
  SET_DEFAULT_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = make.actions(state)

const getters = make.getters(state)

export default {
  state,
  getters,
  actions,
  mutations
}
