var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canShow)?_c('nav',{attrs:{"id":"banner"}},[(_vm.isSearching || _vm.isCartOpen)?_c('div',{staticClass:"is-overlay navbar-overlay"}):_vm._e(),_vm._v(" "),_c('LazyAdvertisement',{attrs:{"ad-unit-nl":"header-nld","ad-unit-en":"header-eng","size":[
      [728, 90],
      [970, 90],
      [970, 250]
    ],"size-mapping":[
      [
        [970, 0],
        [
          [970, 90],
          [970, 250]
        ]
      ],
      [
        [728, 0],
        [728, 90]
      ],
      [[0, 0], []]
    ],"is-banner":true},on:{"updateSize":_vm.updateSize}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }