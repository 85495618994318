export const Level = Object.freeze({
  Editor: 3,
  Admin: 4,
  Correspondent: 8,
  LightEN: 40,
  PlusEN: 41,
  PremiumEN: 42,
  LightNL: 50,
  PlusNL: 51,
  PremiumNL: 52,
  ProEN: 113,
  ProNL: 114
})
