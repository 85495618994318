import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory'

export default ({ app, store, redirect, error }) => {
  const headers = setContext(() => {
    return {
      headers: {
        Authorization: `bearer ${process.env.STRAPI_API_TOKEN}`
      }
    }
  })

  const errorLink = onError(({ operation, graphQLErrors, networkError, forward }) => {
    console.log('networkError', networkError)
    console.log('graphQLErrors', graphQLErrors)

    if (networkError != null) {
      error({
        statusCode: 500
      })
    }
  })

  const retry = new RetryLink({
    delay: {
      initial: 100,
      max: 2000,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error) => {
        const doNotRetryCodes = [500, 400]
        return !!error && !doNotRetryCodes.includes(error.statusCode)
      }
    }
  })

  const link = ApolloLink.from([errorLink, headers, retry])

  return {
    link,
    httpEndpoint: process.env.GRAPHQL_STRAPI_API,
    cache: new InMemoryCache()
  }
}
