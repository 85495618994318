//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Error',
  layout: 'blank',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hasData: false
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.language
      }
    }
  },
  computed: {
    statusCode() {
      return this.error.statusCode || 500
    },
    message() {
      return this.statusCode === 500 ? this.$t('error.common_desc') : this.error.message
    },
    language() {
      return this.$store.get('language/locale') || 'en'
    }
  },
  beforeDestroy() {
    this.$nuxt.$emit('topbanner', {
      isShow: true
    })
  },
  mounted() {
    if (Object.keys(this.error).length === 0) {
      console.log('No error provided to handler')
    }

    this.$nuxt.$emit('topbanner', {
      isShow: false
    })
    this.setBreadcrumb({ isShow: false })
    this.setFilters({ isShow: false, header: '', showFilterTotal: false })

    console.log('error.statusCode', this.error.statusCode)
    console.log('error.message', this.error.message)

    // Workaround for chunk loading issue, see:
    // https://github.com/nuxt/nuxt.js/issues/3389
    if (
      this.error.statusCode === 500 &&
      /^Loading( CSS)? chunk (\d)+ failed\./.test(this.error.message) &&
      window.location.hash !== '#retry'
    ) {
      // the chunk might no longer be available due to a recent redeployment of the page
      // mark the page to don't trigger reload infinitely
      window.location.hash = '#retry'
      window.location.reload(true)
      return
    }

    if (this.error.hasData) this.hasData = true

    if (this.error.response) {
      console.log(this.error.response)
    } else if (this.error.request) {
      console.log(this.error.request)
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', this.error.message)
    }
  },
  methods: {
    onReloadClick() {
      window.location.reload()
    }
  }
}
