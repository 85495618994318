export const ResearchOrderByColumnKeys = Object.freeze({
  TITLE_ASC: 1,
  DATE_DESC: 2,
  TITLE_DESC: 3,
  DATE_ASC: 4
})

export const ResearchOrderByColumnList = Object.freeze([
  { id: ResearchOrderByColumnKeys.DATE_DESC, name: 'Date Desc' },
  { id: ResearchOrderByColumnKeys.DATE_ASC, name: 'Date Asc' },
  { id: ResearchOrderByColumnKeys.TITLE_ASC, name: 'Title Asc' },
  { id: ResearchOrderByColumnKeys.TITLE_DESC, name: 'Title Desc' }
])
